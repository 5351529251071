.search-results {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
    margin-bottom: 50px;
  }
  
  .search-results li {
    padding: 5px;
    border-bottom: 1px solid #ccc;
  }
  
  .search-results li:hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }
  
  .search-input {
    width: 50%; /* Set the width of the input */
    border: 1px solid rgb(9, 39, 17); /* Set the border color to yellow */
    height: 50px; /* Set the height of the input */
    border-radius: 15px;
  }
  .search-input:focus {
    box-shadow: none; /* Remove the default focus shadow */
    border: 1px solid rgb(221, 197, 41); /* Maintain the same border thickness on focus */
  }
  