.App {
	text-align: center;
  }
  
  .App-logo {
	height: 40vmin;
	pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
	.App-logo {
	  animation: App-logo-spin infinite 20s linear;
	}
  }
  
  .App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
  }
  
  .App-link {
	color: #61dafb;
  }
  
  @keyframes App-logo-spin {
	from {
	  transform: rotate(0deg);
	}
	to {
	  transform: rotate(360deg);
	}
  }
  
  /* Stripe Element Styles */
  .StripeElement {
	width: 100%;
	padding: 11px 15px;
	padding-top: "20px";
	padding-bottom: "20px";
  }
  
  .StripeElement--webkit-autofill {
	background: transparent !important;
  }
  
  .FormGroup {
	padding: 0;
	border-style: none;
	background-color: #7795f8;
	will-change: opacity, transform;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #829fff;
	border-radius: 4px;
  }
  
  .FormRow {
	display: flex;
	align-items: center;
	border-top: 1px solid #819efc;
  } 
  
  button.stripe-button {
	display: block;
	font-size: 16px;
	width: calc(100% - 30px);
	height: 40px;
	background-color: #f6a4eb;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #ffb9f6;
	border-radius: 4px;
	color: #fff;
	font-weight: 600;
	cursor: pointer;
	transition: all 100ms ease-in-out;
	will-change: transform, background-color, box-shadow;
	border: none;
	padding-top: "20px";
  } 
  
  button.stripe-button:active {
	background-color: #d782d9;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #e298d8;
	transform: scale(0.99);
  }
  
