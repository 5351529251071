.next-button {
    width: 150px;
    border-radius: 20px;
    border: 2px solid #0a0a39; /* Replace 'darknavy' with the actual color code */
    background-color: transparent;
    color: #0a0a39; /* Replace 'darknavy' with the actual color code */
    transition: background-color 0.3s, border 0.3s, color 0.3s;
    margin-bottom: 100px;
  }
  
  .next-button:hover {
    background-color: rgb(232, 228, 228);
    border: none;
    color: #0a0a39; /* Adjust if you want the text color to change on hover */
  }
  
  .next-button[disabled] {
    background-color: rgb(244, 244, 244);
    color: #2f2f2f; /* Darker gray text */
    border: 2px solid #838383; /* Darker gray border */
  }
  

.subscription-options-card{
    width: 550px;
    height: 250px;
    transition: 0.3s; /* Optional: Adds a transition effect for when the card is hovered over */
    margin: 0 auto; /* This centers the card in the column */
    font-family: 'Canela', serif;
    color: rgb(71, 70, 70); 
    font-size: 16px;
  }

.subscription-options-card-title {
    font-family: 'Canela', serif;
    font-size: 20px; /* Sets the font size for all text within the container */
    margin: auto; /* Optional: Centers the container itself if it has a set width */
  }

  .subscription-optionse-btn {
    width: 150px; /* Set the width of the button */
    border-radius: 25px; /* Set the border radius */
    background-color: #04043c; /* Very dark blue background color */
    color: white; /* White text color */
    border: none; /* Remove default border */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for background and text color */
    margin-top: 20px;
    margin-left: 10px;
    height: 40px;
    font-family: 'Lato', sans-serif; /* Specify the font family */
    font-size: 18px; /* Set the font size */
  }
  
  .subscription-optionse-btn:hover {
    background-color: lightgrey; /* Light grey background on hover */
    color: #0a0a39; /* Very dark blue text color on hover */
  }


  .subscription-options-p{
    margin: 0 auto; /* This centers the card in the column */
    font-family: 'Canela', serif;
    color: rgb(99, 162, 6); 
    font-size: 16px;
    padding: 0;
  }

  .card-badge-container {
    position: relative;
  }
  
  .card-badge {
    position: absolute;
    top: -10px; /* Adjust as needed */
    left: 10px; /* Adjust as needed */
    z-index: 1000; /* Ensure it's above other content */
  }
  
  .subscription-options-title {
    font-family: 'Canela', serif;
    font-size: 24px;
    color: #414141;
    padding-top: 50px;
    padding-bottom: 30px;
  }

  .subscription-options-text {
    font-family: 'Lato', sans-serif; 
    font-size: 16px;
    color: #585858;
    padding-top: 5px;
    padding-bottom: 50px;
  }

  .cancellation-p{
    margin: 0 auto; /* This centers the card in the column */
    font-family: 'Canela', serif;
    color: rgb(248, 138, 5); 
    font-size: 16px;
    padding: 0;
  }

  .cancellation-second-p{
    margin: 0 auto; /* This centers the card in the column */
    font-family: 'Canela', serif;
    color: rgb(79, 78, 78); 
    font-size: 14px;
    padding: 0;
    padding-top: 10px;
  }

  .payment-btn {
    border-radius: 20px;
    border: 2px solid #0a0a39; /* Replace 'darknavy' with the actual color code */
    background-color: transparent;
    color: #0a0a39; /* Replace 'darknavy' with the actual color code */
    transition: background-color 0.3s, border 0.3s, color 0.3s;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .paymente-btn:hover {
    background-color: transparent; 
    border: none;
    color: #0a0a39; /* Adjust if you want the text color to change on hover */
  }


  .practitioners-card{
    width: 600px;
    height: auto;
    margin-bottom: 50px;
    margin-top: 50px;
  }


  .practitioners-button {
    width: 150px;
    border-radius: 20px;
    border: 2px solid #0a0a39; /* Replace 'darknavy' with the actual color code */
    background-color: transparent;
    color: #0a0a39; /* Replace 'darknavy' with the actual color code */
    transition: background-color 0.3s, border 0.3s, color 0.3s;
    margin-bottom: 50px;
  }

  .practitioners-button:hover {
    background-color: rgb(232, 228, 228);
    border: none;
    color: #0a0a39; /* Adjust if you want the text color to change on hover */
  }

  .career-h2{
    font-family: 'Canela', serif;
    font-size: 28px;
    color: #414141;
    padding-top: 20px;
    padding-bottom: 30px;
  }

  .career-title{
    font-size: 20px;
    color: #222222;
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .career-text{
    font-size: 18px;
    color: #222222;
    padding-bottom: 10px;
  }

  .upload-btn {
    width: 100px; /* Set the width of the button */
    border-radius: 25px; /* Set the border radius */
    background-color:gold;
    color: white; /* White text color */
    border: none; /* Remove default border */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for background and text color */
    margin-top: 20px;
    margin-left: 10px;
    height: 40px;
    font-family: 'Lato', sans-serif; /* Specify the font family */
    font-size: 18px; /* Set the font size */
  }
  
  .upload-btn:hover {
    background-color: lightgrey; /* Light grey background on hover */
    color: #0a0a39; /* Very dark blue text color on hover */
  }

  .save-btn {
    width: 100px; /* Set the width of the button */
    border-radius: 25px; /* Set the border radius */
    background-color:rgb(7, 168, 33);
    color: white; /* White text color */
    border: none; /* Remove default border */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for background and text color */
    margin-top: 30px;
    margin-bottom: 100px;
    height: 40px;
    font-family: 'Lato', sans-serif; /* Specify the font family */
    font-size: 18px; /* Set the font size */
  }
  
  .save-btn :hover {
    background-color: lightgrey; /* Light grey background on hover */
    color: #0a0a39; /* Very dark blue text color on hover */
  }


  .more-optionse-btn {
    width: 250px; /* Set the width of the button */
    background-color: white;  /* Very dark blue background color */
    color: #0d0d98; /* White text color */
    border: none; /* Remove default border */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for background and text color */
    margin-top: 20px;
    height: 40px;
    font-family: 'Lato', sans-serif; /* Specify the font family */
    font-size: 16px; /* Set the font size */
    font-weight: bold;
  }


  .nmore-optionse-btn:hover {
    background-color: white;  /* Very dark blue background color */
    border: none;
    color: #020261; /* Adjust if you want the text color to change on hover */
  }