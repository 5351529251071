/* HerbCategories.module.css */

  /* HerbCategories.module.css */

.itemCard {
    width: 250px; /* Fixed width for the card */
    height: 300px; /* Fixed height for the card */
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease; 
  }
  
  .cardImage {
    width: 100%; /* Image width fills the card */
    height: 70%; /* Fixed height for the image */
    object-fit: cover; /* Ensures image covers the area without distortion */
  }
  
  .cardBody {
    height: 40%; /* Remaining height for the card body */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .itemCard:hover {
    transform: scale(1.05); /* Slightly scale up the card on hover */
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.6); /* Bright white shadow for shine effect */
  }
  
  .cardShadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Horizontal offset, vertical offset, blur radius, spread radius, color */
    transition: box-shadow 0.3s ease-in-out; /* Smooth transition for the shadow effect */
  }
  
  .progress-bar {
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 8px;
  }
  
  .progress-bar-fill {
    height: 5px;
    background-color:  rgb(7, 108, 191);
    border-radius: 5px;
    transition: width 0.5s ease-in-out;
  }
  .header-text {
    font-family: 'Lato', sans-serif;
    color: rgb(71, 70, 70); 
    font-size: 26px;
    padding-bottom: 25px;
  }

  .list-group-item {
    background-color: #ebe9e9;
    width: 400px;
  }
  
  /* Active state styling */
  .list-group-item.active {
    background-color: #81bb8e; /* Bootstrap primary color, adjust as needed */
    border-color: #4d925c;
    color: white;
  }
  
  /* General.css or another CSS file */


@font-face {
  font-family: 'Canela';
  src: url('../assets/fonts/Canela_Collection/Canela_Family/Canela-Regular-Trial.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.parent-container {
  background-color:floralwhite;
  height: 100%;
  /* Other styling properties for parent container */
}

.overview-container {
  font-family: 'Canela', serif;
  font-size: 28px; /* Sets the font size for all text within the container */
  text-align: center; /* Centers all text inside the container */
  margin: auto; /* Optional: Centers the container itself if it has a set width */
  width: 1000px;
  color: #363535;
  /* max-width: 900px; Uncomment and adjust if you want to limit the container's width */
}

.overview-title {
  font-weight: bold;
  border-bottom: 2px solid black; /* Creates the underline effect */
  display: inline-block; /* Makes the underline only as wide as the text */
  margin-bottom: 10px; /* Adds some space between the title and the next line */
  font-size: 16px;
  margin-top: 100px;
}


.custom-subscribe-input {
  width: 550px; /* Set the width of the input */
  border: 1px solid rgb(22, 85, 119); /* Set the border color to yellow */
  height: 50px; /* Set the height of the input */
}
.custom-subscribe-input:focus {
  box-shadow: none; /* Remove the default focus shadow */
  border: 1px solid rgb(221, 197, 41); /* Maintain the same border thickness on focus */
}


.input-invalid {
  border: 2px solid red; /* Change border color to red for invalid input */
}

@font-face {
  font-family: 'Agrandir';
  src: url('../assets/fonts/Agrandir/Agrandir-TextBold.otf') format('opentype');
  font-weight: 800; 
  font-style: normal;
}

.my-agrandir-class {
  font-family: 'Agrandir', serif;
}
.email-subscription-title {
  font-family: 'Agrandir', sans-serif; /* Specify the font family */
  font-size: 28px; /* Set the font size */
  color: rgb(3, 7, 38); /* Set the font color */
  text-decoration: none; /* Remove underline by default */
}

.special-edition-title {
  font-family: 'Agrandir', sans-serif; /* Specify the font family */
  font-size: 24px; /* Set the font size */
  color: rgb(3, 7, 38); /* Set the font color */
  text-decoration: none; /* Remove underline by default */
  margin-bottom: 30px;
}

.special-edition-title:hover {
  color: rgb(58, 63, 93); /* Change font color on hover */
  text-decoration: underline; /* Add underline on hover */
}


@font-face {
  font-family: 'Agrandir-heavy';
  src: url('../assets/fonts/Agrandir/Agrandir-GrandHeavy.otf') format('opentype');
  font-weight: 900; 
  font-style: normal;
}

.header-agrandir {
  font-family: 'Agrandir-heavy', sans-serif;
  font-size: 36px;
  margin-bottom: 25px;
}

.text-lato {
  font-family: 'Lato', sans-serif;
  margin-bottom: 30px;
  color: #0a0a39;
}


/* General.css or a specific CSS file */

.no-border-card {
  border: none;
}

.special-edition-parent-container {
  background-color:floralwhite;
  height: 100%;
  /* Other styling properties for parent container */
}

.learn-more-label {
  color: black; /* Initial color of the label */
  background-color: transparent; /* No background */
  border: none; /* No border */
  box-shadow: none; /* No shadow */
  text-decoration: none; /* Remove underline */
  margin-bottom: 50px;
}

.arrow-down-animation {
  display: inline-block;
  animation: moveDown 2s infinite;
  font-size: 36px;
}

@keyframes moveDown {
  0% { transform: translateY(0); }
  50% { transform: translateY(10px); }
  100% { transform: translateY(0); }
}

.options-card{
  width: 550px;
  height: 800px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* This creates the shadow effect */
  border: none; /* Removes the border */
  transition: 0.3s; /* Optional: Adds a transition effect for when the card is hovered over */
  cursor: pointer;
  margin: 0 auto; /* This centers the card in the column */
}

.options-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2); /* Optional: Enhances the shadow effect on hover */
}

.options-title {
  font-family: 'Lato', sans-serif; /* Specify the font family */
  font-size: 24px; /* Set the font size */
  color: black; /* Set the font color */
  text-decoration: none; /* Remove underline by default */
  margin-bottom: 30px;
  padding-top: 20px;
  padding-left: 15px;
  font-weight: bold;
}

.options-title:hover {
  color: rgb(58, 63, 93); /* Change font color on hover */
}

.custom-col {
  padding-right: 15px; /* Adjust the right padding for the left column */
  padding-left: 15px; /* Adjust the left padding for the right column */
}

.checkmark-list {
  list-style-type: none; /* Removes default list bullet */
  padding-left: 0; /* Removes default padding */
}

.checkmark-list li::before {
  content: '✓'; /* Unicode checkmark character */
  color: green; /* Checkmark color */
  font-weight: bold; /* Makes the checkmark bold */
  display: inline-block; /* Needed to apply margin */
  width: 1em; /* Ensures a consistent width for all checkmarks */
  margin-left: 1em; /* Aligns checkmark with the start of the text */
  margin-bottom: "15px";
}


.dosha-card-title{
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
  font-family:Agrandir-bold;
  font-size: 28px;
  color: #04195a;
}


 /* All button */


.custom-subscribe-btn {
  width: 300px; /* Set the width of the button */
  border-radius: 25px; /* Set the border radius */
  background-color: #04043c; /* Very dark blue background color */
  color: white; /* White text color */
  border: none; /* Remove default border */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for background and text color */
  margin-top: 20px;
  margin-left: 10px;
  height: 40px;
  font-family: 'Lato', sans-serif; /* Specify the font family */
  font-size: 18px; /* Set the font size */
}

.custom-subscribe-btn:hover {
  background-color: lightgrey; /* Light grey background on hover */
  color: #0a0a39; /* Very dark blue text color on hover */
}

  .scroll-button {
    border-radius: 20px;
    border: 2px solid #0a0a39; /* Replace 'darknavy' with the actual color code */
    background-color: transparent;
    color: #0a0a39; /* Replace 'darknavy' with the actual color code */
    transition: background-color 0.3s, border 0.3s, color 0.3s;
    margin-bottom: 100px;
  }
  
  .scroll-button:hover {
    background-color: rgb(232, 228, 228);
    border: none;
    color: #0a0a39; /* Adjust if you want the text color to change on hover */
  }


  .custom-button {
    width: 150px;
    background-color: initial; /* Default background color */
    border-color: initial; /* Default border color */
    color: initial; /* Default text color */
    transition: background-color 0.3s; /* Smooth transition for background color */
  }
  
  .custom-button:hover {
    background-color: #4d925c; /* Background color on hover */
    border-color: #4d925c; /* Border color on hover */
    color: white; /* Text color on hover */
  }


  .mission-label {
    font-family: 'Lato', sans-serif;
    margin-top: 50px;
    margin-bottom: 10px;
    color: #0a0a39;
    font-size: 14px;
    font-weight: bold;
  }
  
  .services-title {
    font-family: 'Agrandir', sans-serif; /* Specify the font family */
    font-size: 28px; /* Set the font size */
    color: rgb(4, 9, 47); /* Set the font color */
    text-decoration: none; /* Remove underline by default */
   padding-bottom: 25px;
  }

  .services-text {
    font-family: 'Lato', sans-serif;
    font-size: 18px; /* Set the font size */
    color:rgb(63, 63, 63);
    text-decoration: none; /* Remove underline by default */
  }

  .services-card{
    width: 550px;
    height: 700px;
    border: none; /* Removes the border */
    margin: 0 auto; /* This centers the card in the column */
  }

  .mission-text {
    font-family: 'Lato', sans-serif;
    margin-bottom: 30px;
    color: #0a0a39;
    font-size: 18px;
  }
  

  .services-bigtitle {
    font-family: 'Agrandir-heavy', sans-serif;
    font-size: 28px;
    margin-bottom: 25px;
    color: rgb(21, 25, 61); /* Set the font color */
    text-decoration: none; /* Remove underline by default */
  }

  .results-container {
    background-color:beige;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .mission-container {
    background-color: cornsilk;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  
  .mission-subcontainer {
    font-family: 'Canela', serif;
    font-size: 1.5rem; /* more responsive font size */
    text-align: center;
    margin: auto;
    width: 90%; /* flexible width */
    max-width: 1000px; /* max-width to ensure it doesn't grow too large on bigger screens */
    padding: 0 20px; /* padding to prevent text touching the edge on smaller screens */
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    .mission-subcontainer {
      font-size: 1.2rem; /* smaller font size on smaller devices */
    }
  }
  
  @media (max-width: 480px) {
    .mission-subcontainer {
      font-size: 1rem; /* even smaller font size for very small devices */
    }
  }
  

  .faqs-container {
    background-color:rgb(250, 250, 239);
    width: 100%;
    margin: 0;
    padding: 0;
    /* Add any other styles you need for the container */
  }


.custom-services-btn {
  width: 300px; /* Set the width of the button */
  border-radius: 25px; /* Set the border radius */
  background-color:gold;
  color: white; /* White text color */
  border: none; /* Remove default border */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for background and text color */
  margin-top: 20px;
  margin-left: 10px;
  height: 40px;
  font-family: 'Lato', sans-serif; /* Specify the font family */
  font-size: 18px; /* Set the font size */
}

.custom-services-btn:hover {
  background-color:goldenrod;
  color: #0a0a39; /* Very dark blue text color on hover */
}

.faqs-title {
  font-family: 'Lato', sans-serif;
  margin-top: 50px;
  margin-bottom: 50px;
  color:rgb(45, 44, 44);
  font-size: 36px;
}


.accordion-header {
  text-align: center;
  font-size: 24px; /* Adjust the font size as needed */
  font-family: 'Lato', sans-serif; /* Change to your desired font family */
}

.my-accordion .accordion-button {
  width: 100%;
  justify-content: center !important; /* Center the button content */
  background-color: #fff; /* Change to your desired color */
  color: #333; /* Change text color */
  border: none;
}


.my-accordion .accordion-button:not(.collapsed) {
  background-color: #f8f9fa; /* Light gray background for active header */
  color: #000; /* Dark text color for active header */
}

.my-accordion .accordion-button:focus {
  box-shadow: none;
}

.my-accordion-item {
  border: none;
  margin-bottom: 15px; /* Add padding between items */
}

.my-accordion-item .accordion-body {
  background-color: #fff; /* Change to your desired color for the body */
}


.faq-title {
  font-family: 'Agrandir', sans-serif; /* Specify the font family */
  font-size: 32px; /* Set the font size */
  color:#04043c;
  text-decoration: none; /* Remove underline by default */
  padding-bottom: 100px;
  padding-top: 100px;
}


.sympthom-cards{
  transition: box-shadow 0.3s ease;
  border: none;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  width: 100%;
  aspect-ratio: 1 / 1; /* This keeps the width and height equal, making it square */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none; /* Removes the border */
  overflow: hidden; /* Ensures nothing spills out of the card */
}

.sympthom-cards .card-img-top {
  /* Make the image a circle and center it */
  width: 50%; /* Adjust based on your preference */
  height: auto; /* Maintain aspect ratio */
  border-radius: 50%; /* Makes the image circular */
  object-fit: cover; /* Ensures the image covers the space nicely */
  margin-top: 10px;
  margin-bottom: 20px; /* Adds some space between the image and the card title */
}

.sympthom-cards:hover{
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.2);
}


.social-share-title {
  font-family: 'Agrandir', sans-serif; /* Specify the font family */
  font-size: 18px; /* Set the font size */
  color: rgb(3, 7, 38); /* Set the font color */
  text-decoration: none; /* Remove underline by default */
}

.howitworks-caption{
  font-family: 'Canela', serif;
  font-size: 58px;
  color: #414141;
  padding-top: 20px;
  padding-bottom: 30px;
}

.howitworks-image {
  width: 100%;
  max-width: 100%;
  height: auto; /* or 'auto' for aspect ratio */
  border-radius: 40px;
  object-fit: cover;
  padding: 25px;
}

.howitworks-title {
  font-family: 'Canela', serif;
  font-size: 36px;
  color: #414141;
  padding-top: 10px;
}

.howitworks-number {
  font-family: 'Canela', serif;
  font-size: 80px;
  color: #414141;
}

.howitworks-text {
  font-size: 20px;
  color: #585858;
  padding-top: 25px;
}

.headline-image {
  width: 100%;
  max-width: 100%;
  height: auto; /* or 'auto' for aspect ratio */
  border-radius: 40px;
  object-fit: cover;
  padding: 25px;
  margin-right: 150px;
}

.headline-title {
  font-family: 'Canela', serif;
  font-size: 48px;
  color: #414141;
  padding-top: 50px;
  padding-bottom: 30px;
  padding-left: 100px;
}

.headline-text {
  font-size: 18px;
  color: #363535;
  padding-top: 10px;
  padding-right: 100px;
}

.headline-btn {
  width: 200px; /* Set the width of the button */
  border-radius: 25px; /* Set the border radius */
  background-color:gold;
  color: white; /* White text color */
  border: none; /* Remove default border */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for background and text color */
  margin-top: 20px;
  height: 40px;
  font-family: 'Lato', sans-serif; /* Specify the font family */
  font-size: 18px; /* Set the font size */
}

.headline-btn:hover {
  background-color:goldenrod;
  color: #0a0a39; /* Very dark blue text color on hover */
}

.service-image {
  width: 100%; /* Image width fills the card */
  height: 400px; /* Fixed height for the image */
  object-fit: cover; /* Ensures image covers the area without distortion */
}

.card-carousel-custom {
  width: 550px; /* Set the width of the card */
  margin-right: 25px; /* Add spacing to the right */
  margin-left: 25px; /* Add spacing to the left */
}
.carousel-item {
  display: flex;
  justify-content: center; /* Center cards in the carousel item */
}


/* Order.css */
.row-separator {
  border-bottom: 1px solid #ccc; /* Adjust the color as needed */
  margin-bottom: 25px; /* Adjust spacing as needed */
  padding-top: 20px;
  padding-bottom: 15px;
}


.practitioners-card-img {
  width: 200px;
  height: 200px;
  object-fit: cover;
}


.user-profile-card-title {
  font-weight: bold;
  padding-top: 30px;
  margin-bottom: 10px; /* Adds some space between the title and the next line */
  font-size: 18px;
  margin-top: 100px;
}



.career-title {
  font-family: 'Canela', serif;
  font-size: 48px;
  color: #000113;
  padding-top: 10px;
  padding-left: 150px;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  margin-bottom: 200px;
  background-color:white;

}

.prosandcons-table-container {
  text-align: center;
  width: 800px; /* Or any specific width you prefer */
  max-width: 100%; /* Ensure it doesn't overflow the screen width */
  background-color:rgb(211, 242, 211);
  background-color:floralwhite
}


.proscons-container {
  background-color:rgb(211, 242, 211);
  width: 100%;
  margin: 0;
  padding: 0;
  /* Add any other styles you need for the container */
}

.proscons-title {
  font-family: 'Canela', serif;
  font-size: 38px;
  color: #414141;
  padding-top: 10px;
}

.prosandcons-firstColumn {
  width: 450px; 
  height: 70px;
  font-family: 'Canela', serif; /* Specify the font family */
  font-size: 18px; /* Set the font size */
  color:rgb(41, 40, 40);
  text-align: left;
}


.video-room-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.video-player {
  width: 45%;
  height: 300px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.toolbar {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 10px;
}

.banner-background {
  background-color: #a1c8a1; /* Adjust the color as needed */
  color: white; /* Assuming you want white text over the dark background */
  padding: 0; /* Add some padding around the content */
  width: 100%;
}

.common-services-bg {
  background-color: rgb(250, 250, 239);/* Light beige */
  color: #333; /* Adjust text color as needed */
}

.common-services-container {
  background-color:rgb(250, 250, 239);
  width: 100%;
  margin: 0;
  padding: 0;
  /* Add any other styles you need for the container */
}


.common-types-header {
  font-family: 'Lato', sans-serif;
  font-size: 36px;
  margin-top: 30px;
  margin-bottom: 15px;
  color: rgb(6, 29, 6);
}

.common-types-text {
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  margin-bottom: 15px;
  color: rgb(41, 42, 41);
}


.explorer-card-hover-effect:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2); /* Example shadow effect on hover */
  transform: scale(1.03); /* Slightly enlarge the card */
  transition: all 0.3s ease-in-out; /* Smooth transition for hover effects */
}

/* In your CSS file or in a style block within your component file */
.no-left-padding {
  padding-left: 0 !important;
  margin-left: 0 !important;
}

.no-padding {
  /* padding: 0 !important; */
  padding-left: 20px;
  margin: 20px !important;
}
