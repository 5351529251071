article {
    max-width: 900px;
    margin: auto;
    padding: 20px;
  }

  .article-image {
    width: 100%;
    max-width: 100%;
    height: 600px; /* or 'auto' for aspect ratio */
    border-radius: 5px;
    object-fit: cover;
    margin-bottom: 20px;
  }
  
  .text-content {
    margin-left: 20px; /* Adjust the margin as needed */
    color: rgb(65, 65, 65);
    font-size: 18px;
    font-family: Roboto, sans-serif;
 
  }

  .article h1 {
    font-size: 2em;
    font-family: Garamond, serif;
    color: rgb(65, 65, 65);
    /* more styling */
  }

  .text-content h1 {
    font-size: 2em;
    font-family: Garamond, serif;
    color: rgb(65, 65, 65);
  }
  
  article h2 {
    font-size: 1.5em;
    /* more styling */
  }
  
  article p {
    /* styling for paragraphs */
  }
  
  @font-face {
    font-family: 'Canela';
    src: url('../assets/fonts/Canela_Collection/Canela_Text_Family/CanelaText-Light-Trial.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

.my-canela-class {
    font-family: 'Canela', serif;
    font-size: 28px;
}


@font-face {
  font-family: 'Agrandir';
  src: url('../assets/fonts/Agrandir/Agrandir-GrandHeavy.otf') format('opentype');
  font-weight: 900; 
  font-style: normal;
}

.my-agrandir-class {
  font-family: 'Agrandir', serif;
  font-size: 48px;
}


@font-face {
  font-family: 'Agrandir-Regular';
  src: url('../assets/fonts/Agrandir/Agrandir-Regular.otf') format('opentype');
  font-weight: 900; 
  font-style: normal;
}

.my-agrandir-Regular-class {
  font-family: 'Agrandir-Regular', serif;
  font-size: 28px;
}

.main-article-title {
  font-family: 'Agrandir-Regular', sans-serif; /* Specify the font family */
  font-size: 36px; /* Set the font size */
  color: black; /* Set the font color */
  text-decoration: none; /* Remove underline by default */
}

.main-article-title:hover {
  color: rgb(58, 63, 93); /* Change font color on hover */
  text-decoration: underline; /* Add underline on hover */
}

.main-article-content{
  font-family: 'Lato', sans-serif; /* Specify the font family */
  font-size: 16px; /* Set the font size */
  color: rgb(58, 63, 93);
  text-decoration: none; /* Remove underline by default */
}

.article-overview-title {
  font-weight: bold;
  border-bottom: 3px solid rgb(54, 114, 5); /* Creates the underline effect */
  display: inline-block; /* Makes the underline only as wide as the text */
  margin-bottom: 50px; /* Adds some space between the title and the next line */
  font-size: 28px;
  padding-top: 25px;
}

.zoom-effect {
  transition: transform 0.3s ease; /* Smooth transition for the transform property */
  display: block; /* Ensures the image is block-level for proper scaling */
  max-width: 100%; /* Limits the image width to its container */
  height: auto; /* Maintains the image aspect ratio */
}

.zoom-effect:hover {
  transform: scale(1.01); /* Scales up the image to 110% of its original size on hover */
}

.readmore-btn {
  padding: 0; /* Remove padding */
  background-color: transparent; /* No background */
  border: none; /* No border */
  box-shadow: none; /* No shadow */
  margin-top: 50px;
  color: black !important;
  text-decoration: none !important;
}

.readmore-btn:hover {
  color: darkblue; /* Color changes to dark blue on hover */
  text-decoration: none; /* Ensure underline is not added on hover */
  transform: scale(1.1); /* Zoom effect */
  transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition for color and transform */
}


.row-article-title {
  font-family: 'Lato', sans-serif; /* Specify the font family */
  font-size: 18px; /* Set the font size */
  color: black; /* Set the font color */
  text-decoration: none; /* Remove underline by default */
  padding-bottom: 20px;
}

.row-article-title:hover {
  color: rgb(58, 63, 93); /* Change font color on hover */
  text-decoration: underline; /* Add underline on hover */
}


.herbs-article-title {
  font-family: 'Agrandir', sans-serif; /* Specify the font family */
  font-size: 32px; /* Set the font size */
  color: black; /* Set the font color */
  text-decoration: none; /* Remove underline by default */
  margin-top: 20px;
  margin-bottom: 10px;
}

.herbs-article-content {
  font-family: 'Lato', sans-serif; /* Specify the font family */
  font-size: 18px; /* Set the font size */
  color: black; /* Set the font color */
  text-decoration: none; /* Remove underline by default */
}

.herbs-article-image {
  width: 90%;
  height: 450px; /* or 'auto' for aspect ratio */
  border-radius: 5px;
  object-fit: cover;
  margin-top: 25px;
}

.full-width-container {
  width: 100%; /* Ensure the container takes up the full width */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  margin: 0 auto; /* Remove any margin */
  padding: 0; /* Remove any padding */
  overflow: hidden; /* Prevent content from overflowing */
}

.all-article-content{
  font-family: 'Lato', sans-serif; /* Specify the font family */
  font-size: 18px; /* Set the font size */
  color: rgb(58, 63, 93);
  text-decoration: none; /* Remove underline by default */
}

.article-categoories-title{
  font-family: 'Canela', serif;
  font-size: 38px; /* Set the font size */
  color: rgb(58, 63, 93);
  text-decoration: none; /* Remove underline by default */
}

.article-categoories-subtitle{
  font-family: 'Canela', serif;
  font-size: 24px; /* Set the font size */
  color: rgb(58, 63, 93);
  text-decoration: none; /* Remove underline by default */
}




