.footer {
    padding: 20px 0;
    background-color: #0f182c; /* Light gray background */
    height: 600px;
}

@font-face {
  font-family: 'Agrandir';
  src: url('../assets/fonts/Agrandir/Agrandir-TextBold.otf') format('opentype');
  font-weight: 800; 
  font-style: normal;
}

.footer h5 {
    margin-bottom: 15px;
    color: white;
    font-family: 'Agrandir', sans-serif; /* Specify the font family */
    font-size: 16px; /* Set the font size */
    margin-bottom: 20px;
}

.footer ul {
    list-style-type: none;
    padding: 0;
}

.footer ul li a {
    color: white;
    text-decoration: none;
    transition: color 0.3s;
}

.footer ul li a:hover {
    color: #007bff; /* Change color on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .footer .row {
        text-align: center;
    }
}

.social-icons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
  }

  .social-icons a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #0e0e2e; /* Dark navy color */
    border-radius: 50%;
    padding: 5px;
    margin: 1px; /* Reduced margin for closer icons */
    width: 40px; /* Circle size */
    height: 40px; /* Circle size */
    color: white; /* Icon color */
    text-decoration: none;
  }

  .app-and-social {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers the content */
  }

  .social-icons a .fa {
    font-size: 48px; /* Increased icon size */
  }
  
  .social-icons a:hover {
    background-color: #001f3f; /* Slightly darker navy color on hover */
  }
  
  .app-stores img:hover {
    opacity: 0.9; /* Slight effect on hover */
  }

  .app-stores {
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Aligns content to the right */
    margin-bottom: 20px; /* Space between badges and social media icons */
  }
  
  .app-store-badge {
    margin-bottom: 5px; /* Space below each badge */
  }
  
  .app-stores img {
    max-width: 100px; /* Adjust the size as needed */
    display: block; /* Ensures the image takes the full width of its container */
  }

  .app-store-badge {
    margin-bottom: 10px; /* Space below each badge */
  }
  
  .app-stores img {
    max-width: 150px; /* Adjust the size as needed */
  }

  /* Footer.css */

/* Existing styles remain the same */

/* New Styles for the Copyright and Social Icons Row */
/* Footer List Styling */
.footer-list li {
  border-bottom: 1px solid #ccc; /* Light gray line under each <li> */
  padding-bottom: 10px; /* Spacing under each <li> element */
  margin-bottom: 10px; /* Spacing between <li> elements */
}

/* Existing styles remain unchanged */

/* New styles for the updated structure */
.copyright-social {
  display: flex;
  flex-direction: column; /* Align items vertically */
}

/* Style for copyright and links */
.copyright-info span, .copyright-info ul {
  margin-bottom: 10px; /* Space between copyright, links, and social icons */
}

.copyright-info ul {
  list-style-type: none; /* Remove default list styling */
  padding: 0; /* Remove default padding */
}

.copyright-info ul li {
  margin-bottom: 5px; /* Space between links */
}

/* Ensure social icons are placed at the bottom */
.social-icons {
  margin-top: auto; /* Pushes the social icons to the bottom */
  padding-top: 20px; /* Space above social icons */
}

/* New Styles for the Horizontal Alignment */
.footer .social-icons a {
  margin-left: 10px; /* Space between social icons */
}

.footer .social-icons {
  display: flex; /* Align social icons horizontally */
  align-items: center; /* Center align the icons vertically */
}

.footer .copyright-info {
  display: flex; /* Align copyright information horizontally */
  align-items: center; /* Center align items vertically */
  flex-wrap: wrap; /* Allow items to wrap if space is limited */
}

.footer .copyright-info span,
.footer .copyright-info a {
  margin-right: 10px; /* Space between copyright text and links */
}

