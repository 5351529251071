@font-face {
    font-family: 'Agrandir-heavy';
    src: url('../assets/fonts/Agrandir/Agrandir-GrandHeavy.otf') format('opentype');
    font-weight: 900; 
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Agrandir-bold';
    src: url('../assets/fonts/Agrandir/Agrandir-TextBold.otf') format('opentype');
    font-weight: 700; 
    font-style: normal;
  }

.login-card-title{
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
    font-family:Agrandir-bold;
    font-size: 36px;
    color: #04195a;
}

.login-btn{
    width: 200px; /* Set the width of the button */
    border-radius: 25px; /* Set the border radius */
    background-color: #04043c; /* Very dark blue background color */
    color: white; /* White text color */
    border: none; /* Remove default border */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for background and text color */
    margin-top: 50px;
    height: 40px;
    font-family: 'Lato', sans-serif; /* Specify the font family */
    font-size: 18px; /* Set the font size */
  }
  
  .login-btn:hover {
      background-color: lightgrey; /* Light grey background on hover */
      color: #0a0a39; /* Very dark blue text color on hover */
  }
  
  .google-sign-up-button {
    background-image: url('../images/google-icon.png'); /* Update with the correct path to your icon */
    background-repeat: no-repeat;
    background-position: 10px center; /* Adjust as necessary */
    background-size: 20px; /* Adjust size as necessary */
    padding-left: 40px; /* Adjust padding to make room for the icon */
    background-color: aliceblue;
    color: black;
    border-radius: 25px; /* Set the border radius */
    margin-bottom: 50px;
  }