.custom-subscribe-btn {
    width: 300px; /* Set the width of the button */
    border-radius: 25px; /* Set the border radius */
    background-color: #04043c; /* Very dark blue background color */
    color: white; /* White text color */
    border: none; /* Remove default border */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for background and text color */
    margin-top: 20px;
    margin-left: 10px;
    height: 40px;
    font-family: 'Lato', sans-serif; /* Specify the font family */
    font-size: 18px; /* Set the font size */
  }
  
  .custom-subscribe-btn:hover {
    background-color: lightgrey; /* Light grey background on hover */
    color: #0a0a39; /* Very dark blue text color on hover */
  }

  .scroll-button {
    border-radius: 20px;
    border: 2px solid #0a0a39; /* Replace 'darknavy' with the actual color code */
    background-color: transparent;
    color: #0a0a39; /* Replace 'darknavy' with the actual color code */
    transition: background-color 0.3s, border 0.3s, color 0.3s;
    margin-bottom: 100px;
  }
  
  .scroll-button:hover {
    background-color: rgb(232, 228, 228);
    border: none;
    color: #0a0a39; /* Adjust if you want the text color to change on hover */
  }


  .custom-button {
    width: 150px;
    background-color: initial; /* Default background color */
    border-color: initial; /* Default border color */
    color: initial; /* Default text color */
    transition: background-color 0.3s; /* Smooth transition for background color */
  }
  
  .custom-button:hover {
    background-color: #4d925c; /* Background color on hover */
    border-color: #4d925c; /* Border color on hover */
    color: white; /* Text color on hover */
  }

  .custom-services-btn {
    width: 300px; /* Set the width of the button */
    border-radius: 25px; /* Set the border radius */
    background-color:gold;
    color: white; /* White text color */
    border: none; /* Remove default border */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for background and text color */
    margin-top: 20px;
    margin-left: 10px;
    height: 40px;
    font-family: 'Lato', sans-serif; /* Specify the font family */
    font-size: 18px; /* Set the font size */
  }
  
  .custom-services-btn:hover {
    background-color: lightgrey; /* Light grey background on hover */
    color: #0a0a39; /* Very dark blue text color on hover */
  }

  .readmore-btn {
    color: black; /* Initial color of the label */
    padding: 0; /* Remove padding */
    background-color: transparent; /* No background */
    border: none; /* No border */
    box-shadow: none; /* No shadow */
    text-decoration: none; /* Remove underline */
    margin-top: 25px;
  }
  
  .readmore-btn:hover {
    color: darkblue; /* Color changes to dark blue on hover */
    text-decoration: none; /* Ensure underline is not added on hover */
    transform: scale(1.1); /* Zoom effect */
    transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition for color and transform */
  }

  .header-btn {
    width: 200px; /* Set the width of the button */
    border-radius: 25px; /* Set the border radius */
    background-color:gold;
    color: white; /* White text color */
    border: none; /* Remove default border */
    transition: background-color 0.3s, color 0.3s; 
    margin-top: 40px;
    margin-left: 10px;
    height: 50px;
    font-family: 'Lato', sans-serif; /* Specify the font family */
    font-size: 18px; /* Set the font size */
  }
  
  .header-btn:hover {
    background-color:goldenrod;
    color: #0a0a39; /* Very dark blue text color on hover */
  }

  .agora-button {
    border-radius: 50%; /* Circular shape */
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  

  .online-services-btn {
    width: 300px; /* Set the width of the button */
    border-radius: 25px; /* Set the border radius */
    background-color:rgb(3, 40, 1);
    color: white; /* White text color */
    border: none; /* Remove default border */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for background and text color */
    margin-top: 10px;
    margin-bottom: 30px;
    height: 40px;
    font-family: 'Lato', sans-serif; /* Specify the font family */
    font-size: 18px; /* Set the font size */
  }

  .online-services-btn:hover {
    background-color:rgb(107, 159, 87);
    color: #000300; /* Very dark blue text color on hover */
  }


  .business-button {
    border-radius: 20px;
    border: 2px solid #0a0a39; /* Replace 'darknavy' with the actual color code */
    background-color: transparent;
    color: #0a0a39; /* Replace 'darknavy' with the actual color code */
    transition: background-color 0.3s, border 0.3s, color 0.3s;
    margin-bottom: 30px;
    margin-top: 20px;
  }

  .business-button:hover {
    background-color: rgb(232, 228, 228);
    border: none;
    color: #0a0a39; /* Adjust if you want the text color to change on hover */
  }

  .availability-button {
    border: 2px solid #0a0a39; /* Replace 'darknavy' with the actual color code */
    background-color: transparent;
    color: #0a0a39; /* Replace 'darknavy' with the actual color code */
    transition: background-color 0.3s, border 0.3s, color 0.3s;
  }

  .availability-button:hover {
    background-color: rgb(232, 228, 228);
    border: none;
    color: #0a0a39; /* Adjust if you want the text color to change on hover */
  }