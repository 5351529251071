.menu {
  display: flex;
  justify-content: space-between; /* Adjusted to space-between */
  align-items: center;
  background-color: white;
  padding: 10px;
  width: 100%; /* Ensure the menu takes the full width */
}

.company-logo {
  height: 50px; /* Adjust the size as needed */
  margin-right: 200px;
}


.navbar-collapse {
  justify-content: flex-end !important;
  padding-left: 3rem !important;
  padding-right: 2rem !important;
}

.custom-navbar-container {
  padding-left: 2rem;
  padding-right: 3rem;
}


.menu-list {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.menu-list li {
  margin-right: 20px;
}

.menu-list li:last-child {
  margin-right: 0;
}

.menu-list a,
.menu-button {
  text-decoration: none;
  color: #333;
  padding: 10px 15px;
  display: block;
}

.menu-button {
  border: none;
  cursor: pointer;
  background-color: #e0e0e0;
}

.menu-button.primary {
  background-color: rgb(59, 180, 99);
  /* background-color: #4c95af; */
  color: white;
  margin-left: 5px;
  border-radius: 20px;
  width: 130px;
}

.menu-button:hover {
  background-color: #d5d5d5;
  
}

.nav-item:last-child .nav-link {
  margin-right: 0; /* Removes any right margin */
  padding-right: 0; /* Adjust padding if necessary */
}

.menu-button.primary:hover {
  background-color: rgb(97, 232, 153);
  border-radius: 20px;
}

.navbar-brand {
  margin-right: 0; /* Adjust or remove the margin as needed */
}

.navbar-nav .nav-link:hover {
  background-color: #f0f0f0; /* Light grey background on hover */
  color: rgb(72, 185, 106); /* Change text color on hover */
  border-radius: 5px; /* Optional: adds rounded corners */
  padding: 5px 10px; /* Optional: adds padding for a button-like feel */
  /* transition: background-color 0.3s ease, color 0.3s ease; */
}

.menu-dropdown {
  width: "30px";
  height: "auto";
  borderradius: "50%";
  color: "white";
  backgroundcolor: "#007bff";
  alignitems: "center";
  display: "flex";
  justifycontent: "center";
}

/* Hides caret icon for specific dropdown */
.no-caret .dropdown-toggle::after {
  display: none;
}

@font-face {
  font-family: 'Agrandir';
  src: url('../assets/fonts/Agrandir/Agrandir-GrandHeavy.otf') format('opentype');
  font-weight: 900; 
  font-style: normal;
}

.my-agrandir-class {
  font-family: 'Agrandir', serif;
  font-size: 48px;
}

.custom-logo {
  font-family: 'Agrandir-heavy', serif;
  font-size: 42px;
  color: rgb(13, 47, 24);
  padding-left: 3rem !important;
  margin-left: 3rem !important;
}

.nav-link, .menu-button, .dropdown-toggle {
  height: 40px; /* Example value; adjust as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondary-button  {
  border: 2px solid #012505; /* This matches the default color of btn-outline-secondary */
  border-radius: 25px; /* Set the border-radius */
  background-color: transparent; /* Set the background to transparent */
  color: #6c757d; /* Text color to match the border, assuming default btn-outline-secondary color */
  transition: all 0.3s ease-in-out; /* Smooth transition for hover effects */
  width: 80px;
  text-align: center;
}

.secondary-button:hover {
  background-color: #6c757d; /* Change background on hover */
  color: #fff; /* Change text color on hover */
}


.custom-center-dropdown .dropdown-menu {
  left: 50%;  /* Move the left side of the element to the center of its parent */
  transform: translateX(-50%);  /* Pull the element back to the left by half its own width */
  right: auto; /* Ensure the menu grows towards the left */
}
