
  /* Header.css */
  .header-container {
    position: relative;
    width: 100vw; /* Use viewport width instead of 100% */
    height: 100%; /* Adjust the height as needed */
    overflow: hidden; /* Hide any overflowing content */
  }
  
  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .header {
    position: relative;
    width: 100%;
    height: auto; /* Adjust this value to change the height */
    overflow: hidden;
  }
  
  .background-image-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay with 50% opacity */
    z-index: 1; /* Ensures overlay is above the image but below the text */
  }
  
  .text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: rgb(236, 238, 239);
    z-index: 2; /* Ensures text is above the overlay */
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .button-header {
    border-radius: 20px;
    border: 2px solid white; /* Replace 'darknavy' with the actual color code */
    background-color: transparent;
    color: white; /* Replace 'darknavy' with the actual color code */
    transition: background-color 0.3s, border 0.3s, color 0.3s;
    margin-top: 30px;
    margin-bottom: 100px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .button-header:hover {
    background-color: rgb(232, 228, 228);
    border: none;
    color: #0a0a39; /* Adjust if you want the text color to change on hover */
  }
  
  /* ...rest of your CSS... */

  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(246, 244, 239, 0.5); /* Adjust opacity here (0.5 for 50% opacity) */
  }
  
  @font-face {
    font-family: 'Agrandir';
    src: url('../assets/fonts/Agrandir/Agrandir-GrandHeavy.otf') format('opentype');
    font-weight: 900; 
    font-style: normal;
  }
  
  .h1-header{
    font-family: 'Agrandir', serif;
    font-size: 50px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    margin-bottom: 50px;
    margin-top: 150px;
  }

  .p-header {
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    color: white;
    width: calc(100% - 200px); /* Adjust width to account for margins */
    text-align: center;
    margin: 0 auto; /* Remove specific left/right margins and center the block */
    padding-left: 15%; /* Add equal padding to both sides if needed */
    padding-right: 15%;
    padding-bottom: 25px;
  }
  
  .header-image {
    width: 100%;
    max-width: 100%;
    height: auto; /* or 'auto' for aspect ratio */
    border-radius: 40px;
    object-fit: cover;
    padding: 25px;
    margin-right: 50px;
  }

  .header-title {
    font-family: 'Canela', serif;
    font-size: 48px;
    color: #414141;
    padding-top: 50px;
    padding-bottom: 30px;
    padding-left: 50px;
  }

  .header-text {
    font-size: 4px;
    color: #585858;
    padding-top: 25px;
    padding-right: 35px;
  }
